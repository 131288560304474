import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import {Link as Scroll} from "react-scroll";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Rubik',
    },
    textColor: {
        color: '#C3073F',
    },
    listContent: {
        align: 'left',
    },
    container: {
        color: '#fff',
        borderRadius: 5,
        background: 'rgba(0,0,0,0.7)',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 10,
        paddingBottom: 0,
        textAlign: 'center',
    },
}));

function Info() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Card className={classes.container} id="info">
                <CardContent>
                    <h1>Autohuoltoa joka toimii</h1>
                    <br/>
                    <p>
                        Palvelumme:
                    </p>
                    <ul className={classes.listContent}>
                        <li align="left">huolto ja korjauspalvelut <br/>
                            saatavilla tapauskohtaisesti
                        </li>
                    </ul>
                    <p>
                        Kysy tarjous!
                    </p>
                </CardContent>
                <CardActions>
                    <Scroll to="contact" smooth={true}>
                        <Button size="small" className={classes.textColor}>Yhteystiedot</Button>
                    </Scroll>
                </CardActions>
            </Card>
        </div>
    )
}

export default Info;
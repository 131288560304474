import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Rubik',
    },
    textColor: {
        color: '#C3073F',
    },
    container: {
        color: '#fff',
        borderRadius: 5,
        background: 'rgba(0,0,0,0.7)',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 10,
        paddingBottom: 0,
        textAlign: 'center',
    },
}));

function Contact() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Card className={classes.container} id="contact">
                <CardContent>
                    <h1>Ota yhteytt&auml;</h1>
                    <p>
                        L&auml;het&auml; s&auml;hk&ouml;postia tai soita. <br/>
                        S&auml;hk&ouml;postiosoite: asiakaspalvelu<span
                        className={classes.textColor}>(at)</span>jobdan.fi <br/>
                        Puhelin: +358 (0) 45 7834 7431 / +358 (0) 45 7834 7325 <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        J<span className={classes.textColor}>OBD</span>AN
                    </p>
                </CardContent>
            </Card>
        </div>
    )
}

export default Contact;
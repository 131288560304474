import React, {useState, useEffect} from 'react';
import {Link as Scroll} from 'react-scroll';
import {makeStyles} from "@material-ui/core/styles";
import {Toolbar, AppBar, IconButton, Collapse} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Rubik',
    },
    appbar: {
        paddingLeft: 30,
        background: 'rgba(0,0,0,0.6)',
        color: '#fff',
        //boxShadow: 'none',
    },
    appbarWrapper: {
        width: '100%',
        margin: '0 auto',
    },
    appbarTitle: {
      flexGrow: '1',
    },
    icon: {
        color: '#C3073F',
        size: '2rem',
    },
    textColor: {
        color: '#C3073F',
    },
    container: {
        borderRadius: 5,
        background: 'rgba(0,0,0,0.7)',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 10,
        paddingBottom: 0,
        textAlign: 'center',
    },
    title: {
        color: '#fff',
        fontSize: '2.5rem',
    },
    scrollDown: {
        color: '#C3073F',
        fontSize: '4rem',
    }
}));

function Header() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(true);
    }, []);
    return (
        <div className={classes.root}>
            <AppBar className={classes.appbar}>
                <Toolbar className={classes.appbarWrapper}>
                    <h1 className={classes.appbarTitle}>J<span className={classes.textColor}>OBD</span>AN</h1>
                    <IconButton>
                        <SortIcon className={classes.icon}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Collapse in={checked}
                      {...(checked ? {timeout: 1000} : {})}
                      collapsedHeight={50}>
                <div className={classes.container}>
                    <h1 className={classes.title}>
                        J<span className={classes.textColor}>OBD</span>AN
                    </h1>
                    <Scroll to="info" smooth={true}>
                        <IconButton>
                            <ExpandMoreIcon className={classes.scrollDown}/>
                        </IconButton>
                    </Scroll>
                </div>
            </Collapse>
        </div>
    );
}

export default Header;
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";
import {init, send} from "emailjs-com";

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Rubik',
    },
    textColor: {
        color: '#C3073F',
    },
    container: {
        color: '#fff',
        borderRadius: 5,
        background: 'rgba(0,0,0,0.7)',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 10,
        paddingBottom: 0,
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(1),
    },
});

class EmailForm extends React.Component {

    constructor(props) {
        super(props);
        init('user_iPKUZfGt9moQuZIDGPHYf')
        this.state = {feedback: '', name: 'Name', email: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <form className="test-mailing">
                    <Card className={classes.container}>
                        <CardContent>
                            <h1>L&auml;het&auml; meille viesti</h1>
                            <div><textarea
                                id="test-mailing"
                                name="test-mailing"
                                onChange={this.handleChange}
                                placeholder="Kirjoita viestisi t&auml;h&auml;n"
                                required
                                value={this.state.feedback}
                                style={{width: '100%', height: '150px'}}
                            /></div>
                            <div><textarea
                                id="test-email"
                                name="test-email"
                                placeholder="Kirjoita s&auml;hk&ouml;postiosoitteesi t&auml;h&auml;n"
                                onChange={this.handleEmail}
                                required
                                value={this.state.email}
                                style={{width: '100%', height: '40px'}}
                            />
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={this.handleSubmit}
                                disableElevation>
                                L&auml;het&auml;
                            </Button>
                        </CardActions>
                    </Card>
                </form>
            </div>
        )
    }

    handleChange(event) {
        this.setState({feedback: event.target.value})
    }

    handleEmail(event) {
        this.setState({email: event.target.value})
    }

    handleSubmit(event) {
        const templateId = 'jobdanfi_template';
        this.sendFeedback(templateId, {
            message_html: this.state.feedback,
            from_name: this.state.name,
            reply_to: this.state.email
        })
        this.state = {feedback: '', name: 'Name', email: ''};

    }

    sendFeedback(templateId, variables) {
        send(
            'jobdanfi_webform', templateId,
            variables
        ).then(res => {
            console.log('Email successfully sent!')
        }).catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err)
        }).finally(() => {
            this.setState({feedback: '', name: 'Name', email: ''});
        });
    }
}

export default withStyles(styles)(EmailForm)
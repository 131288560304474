import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";
import Header from './components/Header';
import Info from './components/Info';
import Contact from "./components/Contact";
import Form from './components/Form';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/bg.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
}));

function App() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Header/>
            <Info/>
            <Contact/>
            <Form/>
        </div>
    );
}

export default App;
